import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import CTALanding from "../components/index/cta_devops"
import "../styles/style.css"
import ttm from "../../static/images/icons/web/icon18.svg"
import costes from "../../static/images/icons/web/icon16.svg"
import calidad from "../../static/images/icons/web/icon17.svg"
import icon1 from "../../static/images/icons/web/icon6.svg"
import icon2 from "../../static/images/icons/web/icon34.svg"
import icon3 from "../../static/images/icons/web/icon14.svg"
import icon4 from "../../static/images/icons/web/icon15.svg"
import icon5 from "../../static/images/icons/web/icon35.svg"
import icon6 from "../../static/images/icons/web/icon11.svg"
import icon7 from "../../static/images/icons/web/icon7.svg"
import icon8 from "../../static/images/icons/web/icon10.svg"

const Devops = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas Servicios DevOps e Integración Continua | Qualoom</title>
        <meta name="title" content="Especialistas Servicios DevOps e Integración Continua | Qualoom"/>
        <meta name="description" content="¿Necesitas ayuda con DevOps? Mejora el Rendimiento de tus productos. Consúltanos a través de nuestro Servicio de Asesoramiento Gratuito. ¡Infórmate YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/devops/"/>
        <link rel="canonical" href="https://www.qualoom.es/devops/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/devops/"/>
        <meta property="og:title" content="Especialistas Servicios DevOps e Integración Continua | Qualoom"/>
        <meta property="og:description" content="¿Necesitas ayuda con DevOps? Mejora el Rendimiento de tus productos. Consúltanos a través de nuestro Servicio de Asesoramiento Gratuito. ¡Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/devops/"/>
        <meta property="twitter:title" content="Especialistas Servicios DevOps e Integración Continua | Qualoom"/>
        <meta property="twitter:description" content="¿Necesitas ayuda con DevOps? Mejora el Rendimiento de tus productos. Consúltanos a través de nuestro Servicio de Asesoramiento Gratuito. ¡Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/devops/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"DevOps", "item": {"@id": "https://www.qualoom.es/devops/", "name": "DevOps"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Servicios DevOps</h1>
              <p>DevOps, conjunto de prácticas, métodos y herramientas que mejoran el marco colaborativo entre las áreas de operaciones, desarrollo, calidad y sistemas con el fin de reducir el time-to-market de nuevos productos y servicios, mejorar la calidad de los mismos y reducir los costes productivos de manera continuada.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <CTALanding></CTALanding>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Cuáles son los beneficios de DevOps?</h2><hr></hr>
          <div  className="advantages">
            <div className="advantages-items">
              <div><img src={ttm} alt="Time-to-market"/></div>
              <h3>Time-to-market</h3>
              <p>La automatización de los complejos flujos de trabajo  entre los entornos de trabajo acelera y simplifica los tiempos de desarrollo de software.</p>
            </div>
            <div className="advantages-items">
              <div><img src={costes} alt="Reducción de costes"/></div>
              <h3>Reducción de costes</h3>
              <p>El aumento de la eficacia de los trabajadores, la reducción del tiempo necesario para la entrega de un producto final y la agilidad en el proceso contribuyen directamente a una considerable reducción del coste.</p>
            </div>
            <div className="advantages-items">
              <div><img src={calidad} alt="Calidad del producto"/></div>
              <h3>Aseguramiento de la calidad</h3>
              <p>Con esta metodología se verifica continuamente el estado del software desde las primeras etapas de su creación, pudiendo resolver las posibles incidencias con mayor rapidez y garantizando la calidad del producto.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Qué servicios DevOps ofrece Qualoom?</h2><hr></hr>
          <div className="devops-services">
            <div className="devops-item"><img src={icon1} alt="Gestión de configuración"></img><p>Gestión de configuración</p></div>
            <div className="devops-item"><img src={icon2} alt="Integración continua"></img><p>Integración continua</p></div>
            <div className="devops-item"><img src={icon3} alt="Pruebas automatizadas"></img><p>Pruebas automatizadas</p></div>
            <div className="devops-item"><img src={icon4} alt="Infraestructura como código"></img><p>Infraestructura como código</p></div>
            <div className="devops-item"><img src={icon5} alt="Entregas continuas de producto"></img><p>Entregas continuas de producto</p></div>
            <div className="devops-item"><img src={icon6} alt="Despliegue continuo de artefactos"></img><p>Despliegue continuo de artefactos</p></div>
            <div className="devops-item"><img src={icon7} alt="Pruebas unitarias, integración y regresión"></img><p>Pruebas unitarias, integración y regresión</p></div>
            <div className="devops-item"><img src={icon8} alt="Monitorización continua"></img><p>Monitorización continua</p></div>
          </div><br/>
          <div className="section-main">
            <div className="section-img devops"></div>
            <div className="section-text">
              <h3>Atención personalizada en AWS DevOps</h3>
              <p>A través de la utilización de herramientas que posibiliten la implementación de esta metodología de trabajo entre departamentos de TI, producto, desarrollo y negocio, nuestro equipo de ingeniería pone a disposición conocimiento y experiencia para mejorar la calidad de los productos generados y reducir los costes productivos de manera continuada.</p> 
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Devops
