import React from "react"
import "../../styles/index_cta.css"
import img from "../../../static/images/icons/cta_entregas/icon_devops.svg"
import ActiveCampaignFormInDevOps from '../ActiveCampaignFormInDevOps'

const IndexCTALanding = () => {
  return (
    <div className="cta-landing">
        <div className="cta-content">
          <div className="cta-img"><img src={img} alt="DevOps"></img></div>
          <div className="cta-text">
            <p className="title">Descubre nuestra última entrega y muchas más sobre DevOps suscribiéndote a nuestra newsletter</p>
            <p className="entrega">Metodología y prácticas DevOps</p>
          </div>
        </div>
        <ActiveCampaignFormInDevOps/>
    </div>
  )
}
export default IndexCTALanding